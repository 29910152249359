import React from 'react';

import AboutSection from '../components/about-section';
import Layout from '../components/layout';
import HeroSection from '../components/hero-section';

export default () => (
  <Layout>
    <HeroSection cn="pipb">
      <h2>Proizvodnja i prodaja betona</h2>
    </HeroSection>
    <AboutSection>
      <p>
        Preduzeće Keso-Promet d.o.o. za proizvodnju betona koristi svoju jednu
        betonsku bazu.
      </p>
      <p>
        Radni kapaciteti fabrike je 60m³/h svježe betonske mešavine. Za
        transport i ugradnju betona kompanija poseduje kamion mikser kapaciteta
        4-12m³ betona, kao i beton pumpe koje se nalaze u sklopu miksera.
      </p>
      <p>Za više informacija možete nas pozvati na broj +387 66 713 412.</p>
    </AboutSection>
  </Layout>
);
